<template>
  <b-container
    class="mb-5"
    fluid
  >
    <div class="d-flex justify-content-end mb-3 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-card>
      <b-row class="mb-2">

        <b-col
          cols="12"
          md="9"
        >
          <div class="form-group">
            <label>
              <strong>Search</strong>
            </label>
            <b-input
              v-model="tablePositions.filter.search"
              type="text"
              placeholder="search here"
              debounce="1000"
            />
          </div>
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <div class="form-group">
            <label>
              <strong>Company</strong>
            </label>
            <b-select
              v-model="tablePositions.filter.company"
              :options="filterCompanies"
            />
          </div>
        </b-col>

        <b-col
          cols="12"
        >
          <b-button
            type="button"
            variant="success"
            autocomplete="off"
            @click="onCreate"
          >
            New Position
          </b-button>
        </b-col>

      </b-row>

      <b-table
        ref="tablePositions"
        hover
        responsive
        class="mt-2"
        :per-page="tablePositions.perPage"
        :current-page="tablePositions.currentPage"
        :items="tableProvider"
        :fields="tablePositions.fields"
        :sort-by.sync="tablePositions.sortBy"
        :sort-desc.sync="tablePositions.sortDesc"
        :sort-direction="tablePositions.sortDirection"
        :filter="tablePositions.filter"
        :filter-included-fields="tablePositions.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tablePositions.currentPage * tablePositions.perPage - tablePositions.perPage + (data.index + 1) }}
        </template>

        <template #cell(active)="data">
          {{ data.item.active == 1 ? 'Yes' : 'No' }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <b-button
            size="sm"
            variant="primary"
            @click="onEdit(row.item)"
          >
            Edit
          </b-button>
        </template>

      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tablePositions.perPage"
              :options="tablePositions.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tablePositions.currentPage"
            :total-rows="tablePositions.totalRows"
            :per-page="tablePositions.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-position-form"
      scrollable
      no-close-on-backdrop
      no-enforce-focus
      aria-hidden="false"
      :title="modalTitle"
      @ok="onValidate"
    >
      <ValidationObserver
        ref="formPosition"
      >
        <form
          role="form"
          novalidate
          @submit.prevent
        >
          <ValidationProvider
            #default="{ errors }"
            name="company"
            vid="company"
            rules="required"
          >
            <b-form-group>
              <label for="company">
                <strong>Company</strong>
              </label>
              <b-form-select
                id="company"
                v-model="position.company"
                type="text"
                :options="list.companies"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    -- Please Select Company --
                  </b-form-select-option>
                </template>
              </b-form-select>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
                v-text="errors[0]"
              />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="position code"
            vid="position_code"
            rules="required|max:13"
          >
            <div class="form-group">
              <label for="position_code">
                <strong>Code</strong>
              </label>
              <b-input
                id="position_code"
                v-model="position.position_code"
                type="text"
                placeholder="enter position code"
                autocomplete="off"
                maxlength="13"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy || state.editing"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
                v-text="errors[0]"
              />
            </div>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="position name"
            vid="position_name"
            rules="required|max:150"
          >
            <div class="form-group">
              <label for="position_name">
                <strong>Name</strong>
              </label>
              <b-input
                id="position_name"
                v-model="position.position_name"
                type="text"
                placeholder="enter position name"
                autocomplete="off"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
                v-text="errors[0]"
              />
            </div>
          </ValidationProvider>

          <div
            v-if="state.editing"
            class="form-group"
          >
            <b-form-checkbox
              id="is_active"
              v-model="position.active"
              name="is_active"
              value="1"
              unchecked-value="0"
              switch
              :disabled="state.busy"
            >
              Is Active?
            </b-form-checkbox>
          </div>

        </form>
      </ValidationObserver>

      <template #modal-footer="{ok, cancel}">
        <b-button
          variant="success"
          @click="ok()"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="outline-dark"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>
  </b-container>
</template>
<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, SAdminPosition, SSharedList } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'VAdminPositions',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'Positions'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        editing: false,
        busy: false
      },
      list: {
        companies: []
      },
      position: {
        id: 0,
        position_code: '',
        position_name: '',
        company: null,
        active: 0
      },
      tablePositions: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: '',
          company: 'All'
        },
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'position_code', sortable: true },
          { key: 'position_name', sortable: true },
          { key: 'company.company_name', label: 'company', sortable: true },
          { key: 'active' },
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ]
      }
    }
  },

  computed: {
    modalTitle () {
      return this.state.editing ? 'Edit Position' : 'New Position'
    },

    filterCompanies () {
      return [{ value: 'All', text: 'All' }].concat(this.list.companies)
    }
  },

  mounted () {
    core.index()
    this.getOptions()
  },

  methods: {

    async tableProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tablePositions.busy = true
      const filters = this.objectToUrl({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_company: filter.company,
        filter_text: filter.search
      })

      return await SAdminPosition.get(filters).then(
        ({ data }) => {
          this.tablePositions.totalRows = data.total_rows
          return data.items
        }
      )
        .catch(() => {
          this.tablePositions.totalRows = 0
          return []
        })
        .finally(() => {
          this.tablePositions.busy = false
        })
    },

    async getOptions () {
      await SSharedList.getCompanies().then(
        ({ data }) => {
          this.list.companies = data.map(
            ({ id, company_name }) => ({
              text: company_name,
              value: id
            })
          )
        }
      ).catch(() => {
        this.list.companies = []
      })
    },

    onCreate () {
      this.state.editing = false
      this.position.position_code = ''
      this.position.position_name = ''
      this.position.company = null
      this.position.active = 1
      this.$bvModal.show('modal-position-form')
    },

    onEdit (item) {
      this.state.editing = true
      this.position.id = item.id
      this.position.position_code = item.position_code
      this.position.position_name = item.position_name
      this.position.company = item.company_id
      this.position.active = item.active
      this.$bvModal.show('modal-position-form')
    },

    async onValidate (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.formPosition.validate().then(
        async areAllFieldsValid => {
          if (areAllFieldsValid) {
            this.$swal.fire({
              icon: 'question',
              title: 'Confirmation',
              text: this.state.editing ? 'Update Position?' : 'Create Position?',
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Confirm',
              cancelButtonColor: '#737373',
              showCancelButton: true,
              showLoaderOnConfirm: true,
              preConfirm: () => {
                this.state.busy = true
                if (this.state.editing) {
                  return this.onPut()
                }

                return this.onPost()
              },
              allowOutsideClick: () => !this.$swal.isLoading()
            })
          } else {
            this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            })
          }
        }
      )
    },

    async onPost () {
      return new Promise(
        resolve => {
          SAdminPosition.post(this.position).then(
            ({ data }) => {
              this.$bvModal.hide('modal-position-form')
              this.$swal.fire({
                icon: 'success',
                title: 'Successful',
                text: data.message,
                confirmButtonColor: '#06C270',
                confirmButtonText: 'Dismiss'
              }).then(() => {
                this.$refs.tablePositions.refresh()
              })
            }
          ).catch(
            error => {
              if (error instanceof AxiosError) {
                if (error.code === 422) {
                  this.$refs.formPosition.setErrors(error.message)
                }
              }
            }
          ).finally(resolve)
        }
      ).then(() => {
        this.state.busy = false
      })
    },

    async onPut () {
      return new Promise(
        resolve => {
          SAdminPosition.put(this.position).then(
            ({ data }) => {
              this.$bvModal.hide('modal-position-form')
              this.$swal.fire({
                icon: 'success',
                title: 'Successful',
                text: data.message,
                confirmButtonColor: '#06C270',
                confirmButtonText: 'Dismiss'
              }).then(() => {
                const row = _.find(this.$refs.tablePositions.localItems, {
                  id: data.position.id
                })
                row.position_code = data.position.position_code
                row.position_name = data.position.position_name
                row.company_id = data.position.company_id
                row.company_name = data.position.company.company_name
                row.active = data.position.active
                row.updated_at = data.position.updated_at
              })
            }
          ).catch(
            error => {
              if (error instanceof AxiosError) {
                if (error.code === 422) {
                  this.$refs.formPosition.setErrors(error.message)
                }
              }
            }
          ).finally(resolve)
        }
      ).then(() => {
        this.state.busy = false
      })
    }
  }
}
</script>
